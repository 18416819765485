import { SortDirection } from '../constants';
import { CanvasBackgroundType } from '../ideaConceptCanvas/constants';
import { isCepheidVariant } from '../utils/variants';
import {
  conceptEditorFormFields,
  conceptListColumns,
  IdeaConceptImplementationStatus
} from './constants';

export const IDEA_CONCEPT_ATTACHMENT_EXTENSIONS = [
  'txt',
  'pdf',
  'ppt',
  'pptx',
  'xls',
  'xlsx',
  'doc',
  'docx',
  'png',
  'jpg'
];
export const IDEA_CONCEPT_ATTACHMENT_NAME_MAX_LENGTH = 50;
export const IDEA_CONCEPT_ATTACHMENT_SIZE_MB = 20;
export const IDEA_CONCEPT_TITLE_FIELD_MAXLENGTH = 128;
export const IDEA_CONCEPT_DETAILS_FIELD_MAXLENGTH = isCepheidVariant()
  ? 500
  : 5000;

/**
 * @typedef {Object} ConceptSubpageConfig
 * Controls which platform displays which concept template.
 * Keys are platform names, values are template IDs used in
 * `<ConceptPageLayout/>` and `<ConceptSummary/>`.
 */
export const ConceptSubpageConfig = {
  _default: 'base',
  amdocsavishkar: 'amdocsavishkar',
  budapestglobal: 'classic',
  cepheid: 'cepheid',
  cepheiddemo: 'cepheid',
  cloud: 'classic',
  demo: 'classic',
  experiencing: 'classic',
  financing: 'classic',
  future: 'classic',
  givaudanint: 'givaudanint',
  hackathonnagraduates: 'hackathonnagraduates',
  innovationaward: 'classic',
  m: 'merck',
  'm-apac': 'merck',
  mav: 'classic',
  mccvezetokepzoakademia: 'mcc',
  'mvm-csoport': 'classic',
  smu: 'smu',
  snt: 'classic',
  team: 'smu',
  trial: 'cepheid'
};

export const ConceptCanvasConfig = {
  _default: CanvasBackgroundType.EmpathyMap,
  'conference-board': CanvasBackgroundType.ProblemOpportunityMap,
  'iknow-who': CanvasBackgroundType.ProblemOpportunityMap,
  'm-apac': [
    CanvasBackgroundType.ProblemDeconstruction,
    CanvasBackgroundType.BusinessModel
  ],
  'mvm-csoport': CanvasBackgroundType.ProblemOpportunityMap,
  alfaped: CanvasBackgroundType.InsightCanvas,
  amdocsavishkar: [
    CanvasBackgroundType.BusinessModel,
    CanvasBackgroundType.InsightCanvas,
    CanvasBackgroundType.RiskAnalysis,
    CanvasBackgroundType.StakeholderMap
  ],
  blee: CanvasBackgroundType.ProblemOpportunityMap,
  cepheid: CanvasBackgroundType.CepheidBusinessModel,
  cepheiddemo: CanvasBackgroundType.CepheidBusinessModel,
  cloud: CanvasBackgroundType.ProblemOpportunityMap,
  egis: CanvasBackgroundType.ProblemOpportunityMap,
  experiencecon: CanvasBackgroundType.ProblemOpportunityMap,
  experiencing: CanvasBackgroundType.ProblemOpportunityMap,
  financing: CanvasBackgroundType.SmuValueProposition,
  future: CanvasBackgroundType.SocialImpactMap,
  givaudanint: CanvasBackgroundType.GivaudanInt,
  gthree: CanvasBackgroundType.ProblemOpportunityMap,
  hackathonnagraduates: CanvasBackgroundType.ProblemOpportunityMap,
  ihub: CanvasBackgroundType.ProblemOpportunityMap,
  ilumalabs: CanvasBackgroundType.ProblemOpportunityMap,
  innovationaward: CanvasBackgroundType.BusinessModel,
  lantmannen: CanvasBackgroundType.InsightCanvas,
  m: [
    CanvasBackgroundType.ProblemDeconstruction,
    CanvasBackgroundType.BusinessModel
  ],
  mav: CanvasBackgroundType.ProblemOpportunityMap,
  mccvezetokepzoakademia: CanvasBackgroundType.MissionModel,
  partnerhub: CanvasBackgroundType.ProblemOpportunityMap,
  richter: CanvasBackgroundType.ProblemOpportunityMap,
  snt: CanvasBackgroundType.RiskAnalysis,
  smu: [
    CanvasBackgroundType.BusinessModel,
    CanvasBackgroundType.BusinessModel2,
    CanvasBackgroundType.InsightCanvas,
    CanvasBackgroundType.SmuValueProposition,
    CanvasBackgroundType.SmuZones
  ],
  spaceforfood: CanvasBackgroundType.ProblemDeconstruction,
  team: [
    CanvasBackgroundType.BusinessModel,
    CanvasBackgroundType.SmuValueProposition
  ],
  trial: CanvasBackgroundType.CepheidBusinessModel,
  upthereeverywhere: CanvasBackgroundType.ProblemOpportunityMap
};

/**
 * Config objects keyed by platform names and concept form fields.
 * These settings are consumed by the concept subpage forms.
 */
export const ConceptFormConfig = {
  'mvm-csoport': {
    [conceptEditorFormFields.BENEFITS]: {
      required: true
    },
    [conceptEditorFormFields.COSTS]: {
      required: true
    },
    [conceptEditorFormFields.PLANNED_ACTIVITIES]: {
      required: true
    }
  }
};

/**
 * Config objects keyed by platform names and concept form fields.
 * These settings are consumed by the quick concept form.
 */
export const InsightFormConfig = {
  givaudanint: {
    [conceptEditorFormFields.BENEFITS]: {
      hidden: true
    },
    [conceptEditorFormFields.COSTS]: {
      hidden: true
    },
    labelContext: 'givaudanint'
  },
  innovationaward: {
    [conceptEditorFormFields.BENEFITS]: {
      required: true
    },
    [conceptEditorFormFields.COSTS]: {
      required: true
    },
    [conceptEditorFormFields.PLANNED_ACTIVITIES]: {
      required: true
    },
    [conceptEditorFormFields.TEAM]: {
      minLength: 1
    },
    forceExpanded: true,
    labelContext: 'innoawardinsight'
  },
  'mvm-csoport': {
    forceExpanded: true,
    ...ConceptFormConfig['mvm-csoport']
  }
};

/**
 * @enum {number} IdeaConceptEvaluationFormConfig
 * Controls the range and steps of concept evaluation criteria
 * @readonly
 */
export const IdeaConceptEvaluationFormConfig = {
  /** The minimum selectable value, intended for skipping evaluation for the given criterion. This value isn't taken into account by the API when calculating the score. */
  CRITERION_MIN: 0,
  /** The maximum selectable value for the criterion */
  CRITERION_MAX: 10,
  /** The incremental value by which scores can be changed in the input fields */
  CRITERION_STEP: 1
};
export const CepheidConceptEvaluationFormConfig = {
  /** The minimum selectable value, intended for skipping evaluation for the given criterion. This value isn't taken into account by the API when calculating the score. */
  CRITERION_MIN: 0,
  /** The maximum selectable value for the criterion */
  CRITERION_MAX: 3,
  /** The incremental value by which scores can be changed in the input fields */
  CRITERION_STEP: 1
};
export const CONCEPT_EVAL_CRITERIA_MAXLENGTH = 40;

export const CONCEPT_LIST_PAGE_SIZE = 10;
export const CONCEPT_LIST_ITEM_MAX_WORD_COUNT = 200;
export const CONCEPT_LIST_DEFAULT_SORT = {
  sortBy: conceptListColumns.CREATE_TIME,
  sortDirection: SortDirection.DESC
};
export const CONCEPT_LIST_DEFAULT_STAGE_FILTER =
  IdeaConceptImplementationStatus.VALIDATION;
